/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap';

@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';

@import '~ngx-toastr/toastr';

body {
    width: 100vw;
    height: 100vh;
}

.content {
  padding: 0 1rem;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.user-menu > .nav-link {
    cursor: pointer;
}

.user-menu > .nav-link:after {
    content: none;
}

.user-menu > .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0;
    width: 280px;
}

.user-menu > .dropdown-menu,
.user-menu > .dropdown-menu > .user-body {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.user-menu > .dropdown-menu > li.user-header {
    height: 175px;
    padding: 10px;
    text-align: center;
}

.user-menu > .dropdown-menu > li.user-header > img {
    z-index: 5;
    height: 90px;
    width: 90px;
    border: 3px solid;
    border-color: transparent;
    border-color: rgba(255, 255, 255, 0.2);
}

.user-menu > .dropdown-menu > li.user-header > p {
    z-index: 5;
    font-size: 17px;
    margin-top: 10px;
}

.user-menu > .dropdown-menu > li.user-header > p > small {
    display: block;
    font-size: 12px;
}

.user-menu > .dropdown-menu > .user-body {
    border-bottom: 1px solid #495057;
    border-top: 1px solid #dee2e6;
    padding: 15px;
}

.user-menu > .dropdown-menu > .user-body::after {
    display: block;
    clear: both;
    content: '';
}

@media (min-width: 576px) {
    .user-menu > .dropdown-menu > .user-body a {
        background: #ffffff !important;
        color: #495057 !important;
    }
}

.user-menu > .dropdown-menu > .user-footer {
    background-color: #f8f9fa;
    padding: 10px;
}

.user-menu > .dropdown-menu > .user-footer::after {
    display: block;
    clear: both;
    content: '';
}

.user-menu > .dropdown-menu > .user-footer .btn-default {
    color: #6c757d;
}

@media (min-width: 576px) {
    .user-menu > .dropdown-menu > .user-footer .btn-default:hover {
        background-color: #f8f9fa;
    }
}

.user-menu .user-image {
    border-radius: 50%;
    float: left;
    height: 2.1rem;
    margin-right: 10px;
    margin-top: -2px;
    width: 2.1rem;
}

@media (min-width: 576px) {
    .user-menu .user-image {
        float: none;
        line-height: 10px;
        margin-right: 0.4rem;
        margin-top: -8px;
    }
}

.user-menu .user-image {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 0;
    margin-left: -8px;
}

.bg-gray-basic{
  background-color: #e9ecef;
}

.big-rem{
  width: 5rem;  height: 5rem;
}

.justify-content-around {
  margin-bottom: 1rem;
}

.min-width-100px{
  min-width: 100px;
}
.min-width-60px {
  min-width: 60px;
}
.min-width-160px{
  min-width: 160px;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.table thead th.sortable {
  cursor: pointer;
  background-position: right;
  background-repeat: no-repeat;
  padding-right: 30px!important;
}

.table thead th.th-inner {
  padding: .75rem;
  vertical-align: bottom;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table thead th.both {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC");
}

.table thead th.desc {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=");
}

.table thead th.asc {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==");
}

.page-item.active .page-link {
  background-color: #28a745;
  border-color: #28a745;
}
.page-link:hover {
  color: #218838;
}
.page-link {
  color: #28a745;
}
.modal-dialog .overlay {
  background-color: rgb(255, 255, 255, .7);
}

.column-min {
  width: 1px;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
